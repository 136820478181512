import React, { FC, useEffect, useState } from 'react';
import AccordionWidgetIcon from 'app/modules/posts/components/accordionWidget/AccordionWidgetIcon';
import AccordionWidget from 'app/modules/posts/components/AccordionWidget';
import AccordionWidgetTitle from 'app/modules/posts/components/accordionWidget/AccordionWidgetTitle';
import { PostDetail } from 'types/PostDetail';
import LabelTag from 'components/shared/LabelTag';
import { Tag } from '@kontentino/ui';
import PageTagsSelect from 'components/shared/PageTagsSelect';
import { PostLabel } from 'types/Post';
import { useMutation } from 'react-query';
import PostApi from 'api/post';
import { useInvalidatePostDetailQueries } from 'app/modules/posts/hooks/useInvalidatePostsQueries';
import LabelsView from 'components/shared/detail/LabelsView';
import { useUserPermissions } from 'app/hooks/useUserPermissions';
import { UserPermission } from 'constants/userPermission';
import { queryClient } from 'api/client';
import { queryKey } from 'constants/queryKey';
import { useToast } from 'app/hooks/useToast';

type Props = {
  isLoading: boolean;
  isError: boolean;
  accordionProps?: Partial<React.ComponentProps<typeof AccordionWidget>>;
  post: {
    id?: number;
    pageId?: number;
    labels: PostDetail['tags'];
  };
};

const Labels: FC<Props> = ({ isLoading, isError, accordionProps, post }) => {
  const [tags, setTags] = useState<PostLabel[]>(post.labels);
  const visibleTags = post.labels.slice(0, 2);
  const truncatedTagsCount = post.labels.length - visibleTags.length;

  const toast = useToast();
  const invalidatePostDetail = useInvalidatePostDetailQueries();
  const userPermissions = useUserPermissions();

  const displayEditableTags =
    userPermissions.hasEvery(UserPermission.POST_EDIT) && post.id;

  useEffect(() => {
    if (displayEditableTags) {
      setTags(post.labels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post.labels, post.id]);

  const savePostPartial = useMutation(PostApi.savePartial, {
    onSuccess() {
      if (post.id) {
        invalidatePostDetail(post.id);
        queryClient.invalidateQueries(queryKey.posts());
        queryClient.invalidateQueries(queryKey.postsMeta());
        toast('Post labels updated', 'success');
      }
    },
    onError() {
      toast('Failed to update post labels', 'error');
    },
  });

  const handleTagsOnBlur = (postId: number) => {
    savePostPartial.mutate({
      postId,
      values: { tags },
    });
  };

  return (
    <AccordionWidget
      data-cy="post-preview-labels"
      icon={<AccordionWidgetIcon variant="labels" />}
      title={<AccordionWidgetTitle variant="labels" />}
      titleSuffix={(isOpen) =>
        visibleTags.length > 0 &&
        !isOpen && (
          <div className="tw-flex tw-items-center tw-justify-end tw-gap-1">
            {visibleTags.map((tag) => (
              <LabelTag
                key={tag.id}
                size="small"
                className="tw-max-w-[100px]"
                name={tag.name}
                color={tag.color}
              />
            ))}
            {truncatedTagsCount > 0 && (
              <Tag size="small" className="tw-whitespace-nowrap">
                + {truncatedTagsCount}
              </Tag>
            )}
          </div>
        )
      }
      {...accordionProps}
    >
      {displayEditableTags ? (
        <PageTagsSelect
          pageId={post.pageId ?? null}
          value={tags}
          onChange={setTags}
          isDisabled={isLoading || isError}
          onBlur={() => (post.id ? handleTagsOnBlur(post.id) : undefined)}
        />
      ) : (
        <LabelsView
          labels={post.labels}
          isLoading={isLoading}
          isError={isError}
        />
      )}
    </AccordionWidget>
  );
};

export default Labels;
