import React, { FC } from 'react';
import i18n from 'i18n';
import type { PostLabel } from 'types/Post';
import { components } from 'react-select';
import ColorUtils from 'app/utils/color';
import { Icon, Select } from '@kontentino/ui';
import { useTheme } from 'utils/hooks/useTheme';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
type Props = {
  options: PostLabel[];
  value: PostLabel[];
  placeholder?: string;
  isDisabled?: boolean;
  onChange(value: PostLabel[]): void;
  onCreate(value: string): void;
  onMenuOpen?(): void;
  onFocus?(): void;
  onBlur?(): void;
};

const toOptionMapper = (option: PostLabel) => ({
  value: option.id,
  label: option.name,
  color: option.color,
});

const toLabelMapper = (option: ReturnType<typeof toOptionMapper>) => ({
  id: option.value,
  name: option.label,
  color: option.color,
});

const TagInputWithSuggestions: FC<Props> = ({
  placeholder = i18n.typeNewLabel,
  value,
  isDisabled,
  options,
  onChange,
  onCreate,
  onMenuOpen,
  onFocus,
  onBlur,
}) => {
  const theme = useTheme();
  return (
    <UserPermissionGate
      scopes={UserPermission.TAG_CREATE}
      deniedProps={{ isCreatable: false }}
    >
      <Select
        isCreatable
        onFocus={onFocus}
        onBlur={onBlur}
        isMulti
        isDisabled={isDisabled}
        placeholder={placeholder}
        options={options.map(toOptionMapper)}
        value={value.map(toOptionMapper)}
        onChange={(option) => onChange(option.map(toLabelMapper))}
        onCreateOption={onCreate}
        closeMenuOnSelect={false}
        onMenuOpen={onMenuOpen}
        isValidNewOption={(inputValue, values, options) =>
          !options.find((option) => option.label === inputValue.trim())
        }
        components={{
          MultiValueRemove: (props) => (
            <components.MultiValueRemove {...props}>
              <Icon icon={faXmark} size="sm" />
            </components.MultiValueRemove>
          ),
          Option: (props) => (
            <components.Option
              {...props}
              className="tw-break-words"
              innerProps={{
                ...props.innerProps,
                // @ts-ignore
                'data-name': `labels-select_${
                  props.label.includes('Create "') ? 'create' : 'select'
                }-option`,
              }}
            />
          ),
        }}
        styles={{
          multiValue: (provided, { data }) => ({
            ...provided,
            borderRadius: 8,
            backgroundColor: data.color,
            overflow: 'hidden',
            height: 24,
            padding: '4px 6px 4px 8px',
            alignItems: 'center',
          }),
          multiValueRemove: (provided, { data }) => ({
            ...provided,
            backgroundColor: data.color,
            borderRadius: 0,
            color: ColorUtils.hasLowContrastRatio(
              theme.colors.grayscale['100'],
              data.color ?? theme.colors.grayscale['100'],
              2,
            )
              ? theme.colors.white
              : theme.colors.grayscale['100'],
            borderTopRightRadius: 4,
            paddingTop: 1,
            borderBottomRightRadius: 4,
            ':hover': {
              backgroundColor: data.color,
              color: theme.colors.danger['100'],
            },
          }),
          multiValueLabel: (provided, { data }) => ({
            ...provided,
            padding: 0,
            fontWeight: 500,
            lineHeight: 16,
            color: ColorUtils.hasLowContrastRatio(
              theme.colors.grayscale['180'],
              data.color ?? theme.colors.grayscale['180'],
            )
              ? theme.colors.white
              : theme.colors.grayscale['180'],
            paddingRight: 2,
            paddingLeft: 0,
            borderRadius: 0,
          }),
        }}
      />
    </UserPermissionGate>
  );
};

export default TagInputWithSuggestions;
